<template>
 <Main class="mt-20">
  <sdModal :onCancel="handleFileUploadCancel" :visible="showFileUploadRef" title="Document uploaden" type="primary">
   <ModalMain>
    <div class="create-account-form">
     <a-form :model="fileUpload" layout="vertical" @finish="handleUpload">
      <a-row :gutter="30">
       <a-col :xs="24">
        <div class="clearfix">
         <a-upload-dragger :before-upload="beforeUpload" :file-list="fileList" :remove="handleFileRemove"
                           multiple="false">
          <a-button :disabled="fileList.length === 1">
           <upload-outlined></upload-outlined>
           Selecteer bestand
          </a-button>
         </a-upload-dragger>
        </div>
       </a-col>
       <a-col
        :xs="24"
       >
        <a-button
         :disabled="fileList.length === 0"
         :loading="loadingFileUpload"
         html-type="submit"
         size="large"
         style="margin-top: 16px"
         type="primary"
        >
         {{ uploading ? 'Uploading' : 'Start Upload' }}
        </a-button>
       </a-col>
      </a-row>
     </a-form>
    </div>
   </ModalMain>
  </sdModal>
  <a-row v-if="loadingOffer" :gutter="30">
   <a-col :xs="24">
    <div class="spin">
     <a-spin/>
    </div>
   </a-col>
  </a-row>
  <a-row v-visible="!loadingOffer" v-if="loadingOffer || (!loadingOffer && !loadingOfferError)" :gutter="30">

   <a-col :md="12" :xs="24" v-if="!offer?.isSignable && offer?.isExpired">
    <sdCards headless>
     Geachte {{ offer?.relation?.fullName }},<br>
     <br>
     Hartelijk dank voor je offerte aanvraag, helaas is dit aanbod verlopen. Neem contact op voor een nieuw aanbod.
    </sdCards>
   </a-col>
   <a-col :md="12" :xs="24" v-if="!offer?.isSignable && offer?.isSigned">
    <sdCards headless>
     Geachte {{ offer?.relation?.fullName }},<br>
     <br>
     <span v-if="offer?.type === 'SHOP'">
          <span v-if="!offer?.canPay && offer?.product.name !== 'Batterijnet Zakelijk'">
          Hartelijk dank voor het accepteren van de offerte met kenmerk {{ offer?.id }}. Wij gaan direct voor je aan de slag en houden je op de hoogte van de voortgang van uw aanvraag.
          </span>
          <span v-if="offer?.canPay && !offer?.hasPaid&& offer?.product.name !== 'Batterijnet Zakelijk'">
          Bedankt voor je bestelling! Klik op de knop hieronder om te betalen en je bestelling af te ronden. Zodra de betaling is afgerond, beginnen we direct met het verwerken van je bestelling.
          <br/><br/><a-button size="large" class="btn-signin mt-5" type="primary" :loading="loadingPayOffer"
                              :disabled="loadingPayOffer"
                              @click.prevent="pay">Betalen</a-button>
           </span>
          <span v-if="offer?.canPay && offer?.hasPaid&& offer?.product.name !== 'Batterijnet Zakelijk'">
            Bedankt voor je betaling! Je transactie is succesvol afgerond. Wij gaan direct voor je aan de slag en houden je op de hoogte van de voortgang van uw aanvraag. </span>
               <span v-if="offer?.product.name === 'Batterijnet Zakelijk'">
            Hartelijk dank voor uw bestelling van {{ offer?.product?.name }}. Wij gaan direct voor je aan de slag en houden je op de hoogte van de voortgang van uw bestelling.</span>
     <span v-if="offer?.type === 'ENERGY' || offer?.type === 'SOLARSIMPLE' ">
                      Hartelijk dank voor uw bestelling van {{ offer?.product?.name }}. Wij gaan direct voor je aan de slag en houden je op de hoogte van de voortgang van uw bestelling.
          </span>
     </span>
     <span v-if="offer?.type === 'LEAD'">
          Hierbij bevestigen wij uw afspraak met één van onze energieadviseurs, wij zijn verheugd om u te kunnen helpen bij het vinden van de beste energieoplossing voor uw behoeften.
         </span>
     <span v-if="offer?.type === 'SVM'">
                      Hartelijk dank voor uw aanvraag van een SVM-Rapport. Wij gaan direct voor je aan de slag en houden je op de hoogte van de voortgang van uw aanvraag.
          </span>
     <span v-if="offer?.type === 'HYDROMX'">
Hartelijk dank voor uw aanvraag bij Efficient Rendement voor het vervangen van de vloeistof in uw CV-installatie met het gepatenteerde Hydromx. Wij gaan direct aan de slag en houden u op de hoogte van de voortgang van uw aanvraag.
          </span>
     <span v-if="offer?.type === 'SOLAR' ">
             <span v-if="offer?.product.isProxy">
              Hartelijk dank voor het accepteren van onze machtiging met kenmerk {{ offer?.id }}. Wij gaan direct voor je aan de slag en houden je op de hoogte van de voortgang van uw aanvraag.
             </span>
             <span v-else>
               Hartelijk dank voor het accepteren van ons aanvraag {{ offer?.product?.name }} met kenmerk {{
               offer?.id
              }}. Wij gaan direct voor je aan de slag en houden je op de hoogte van de voortgang van uw aanvraag.
             </span>
          </span>
     <span v-if="offer?.type === 'SOLARCONFIRM' ">
             Hartelijk dank voor het accepteren van ons bespaarplan met kenmerk {{ offer?.id }}. Wij gaan direct voor je aan de slag en houden je op de hoogte van de voortgang van uw aanvraag.
          </span>
    </sdCards>
   </a-col>
   <a-col :md="12" :xs="24" v-if="offer?.isSignable">
    <sdCards headless>
     Geachte {{ offer?.relation?.fullName }},<br>
     <br>
     <span v-if="offer?.type === 'ENERGY' || offer?.type === 'SOLARSIMPLE'">
          Hartelijk dank voor je offerte aanvraag met kenmerk {{ offer?.id }}. Wij zijn ervan overtuigd dat wij je namens
          {{ offer?.sender?.senderName }} een aantrekkelijk aanbod doen met {{ offer?.product?.name }}.
          </span>
     <span v-if="offer?.type === 'LEAD'">
           Hartelijk dank voor het aangename telefoongesprek zojuist. We zijn verheugd om u te kunnen helpen bij het vinden van de beste energieoplossing voor uw behoeften.
           <br/><br/>
          Tijdens deze afspraak zullen we:
         <br/>
         - Uw huidige energieverbruik en kosten evalueren.<br/>
         - De resultaten van de prijsvergelijking bespreken.<br/>
         - De beste energieleverancier en -tarief voor uw situatie aanbevelen.<br/>
         - Eventuele vragen die u heeft beantwoorden en zorgen dat u volledig geïnformeerd bent.<br/><br/>
Wij kijken ernaar uit om u te spreken en u te helpen bij het realiseren van besparingen op uw energierekening. klik op de button hieronder om uw afspraak te bevestigen:
          </span>
     <span v-if="offer?.type === 'SVM'">
            Hartelijk dank voor uw interesse en een aanvraag van een SVM-Rapport, u bent bij ons in goede handen.
          </span>
     <span v-if="offer?.type === 'SHOP'">
Hartelijk dank voor het aanvragen van een offerte met kenmerk {{ offer?.id }}. Wij zijn ervan overtuigd dat wij je een aantrekkelijk aanbod doen.<br/><br/>
          Op je mobiele apparaat moet je naar beneden scrollen om de offerte te kunnen bekijken. De pdf-viewer heeft een swipe (veegfunctie) om van pagina te wisselen.<br/><br/>
          </span>
     <span v-if="offer?.type === 'HYDROMX'">
Hartelijk dank voor het aanvragen van een offerte met kenmerk {{ offer?.id }}. Wij zijn ervan overtuigd dat wij je namens Efficiënt Rendement een aantrekkelijk aanbod doen.<br/><br/>
          Op je mobiele apparaat moet je naar beneden scrollen om de offerte te kunnen bekijken. De pdf-viewer heeft een swipe (veegfunctie) om van pagina te wisselen.<br/><br/>
          Efficiënt Rendement vervangt de bestaande vloeistof in jouw cv-installatie met het gepatenteerde Hydromx®️. Hydromx®️ is speciaal ontwikkeld om cv-ketels, en (hybride) warmtepompen efficiënter te laten werken. Hierdoor gaan ze niet alleen langer mee, maar worden ze ook direct beschermd tegen corosie en kalkaanslag.
          </span>
     <span v-if="offer?.type === 'SOLAR'">
           <span v-if="offer?.product.isProxy">
               Wij zullen aanbieders van zonnepanelen toetsen op de onderstaande punten om u een goed advies te kunnen geven met de beste voorwaarden.
                <br/><br/>
                De aanbieders worden getoetst op de volgende punten:<br/>
                - Voorraad<br/>
                - Installatietermijn<br/>
                - Kwaliteit van de zonnepanelen<br/>
                - Kwaliteit van de Omvormers<br/>
                - Kwaliteit van de Onderconstructie<br/>
                - Kwaliteit van de kabels<br/>
                - Certificering van de monteurs en elektriciens
             </span>
             <span v-else>
                Wij hebben zorgvuldig een aanbod op maat voor u samengesteld met kenmerk {{ offer?.id }}. Wij zijn ervan overtuigd dat wij je een aantrekkelijk aanbod doen met {{
               offer?.product?.name
              }}.
              <!--                 <span v-if="offer?.product?.files?.length > 0">-->
              <!--                 <br/><br/>-->
              <!--                 Bekijk de voorwaarden van toepassing op dit aanbod:<br/>-->
              <!--                 -	Contract en Productvoorwaarden<br/>-->
              <!--                 -	Algemene Voorwaarden<br/>-->
              <!--                 -	Overige voorwaarden of documentatie<br/>-->
              <!--                 </span>-->
               </span>
          </span>
     <span v-if="offer?.type === 'SOLARCONFIRM' ">
          Welkom bij onze bespaarplan voor zonnepanelen! We willen u feliciteren met uw keuze om te investeren in duurzame energie. In ons plan hebben we de drie beste aanbieders van zonnepanelen voor u geselecteerd, zodat u verzekerd bent van kwaliteit en een goede prijs. We hopen dat u tevreden zult zijn met uw aankoop en dat u binnenkort zult genieten van de voordelen van zonne-energie.
          </span>
    </sdCards>

    <Aom v-if="!loadingOffer && offer?.energyCalculation && offer?.type === 'ENERGY' && !offer?.product.isProxy"
         :calculations="offer.energyCalculation" :type="offer?.energyCalculation?.product?.allowedCustomerType"/>

    <sdCards title="Foto's uploaden" v-if="offer?.type === 'SOLAR' && offer?.isSigned">
     <section>
      Om de plaatsing van uw panelen soepeler te laten verlopen vragen wij u om foto’s van het volgende te
      uploaden:<br/>
      <ul style="list-style: square;padding-left: 20px">
       <li>Een duidelijke foto van uw dak waar u de panelen zou willen hebben.</li>
       <li>Een duidelijke foto van de meterkast waar de meter ook goed zichtbaar is.</li>
       <li>Een detailfoto van de stroommeter van de netbeheerder.</li>
      </ul>
     </section>
     <!--          <FilesListTable/>-->
     <a-row class="mt-6">
      <a-col :sm="12" :xs="24">
       <div class="sDash_form-action">
        <a-button class="btn-signin" size="large" type="primary" @click.prevent="showFileUpload">
         Toevoegen
        </a-button>
       </div>
      </a-col>
     </a-row>
    </sdCards>

    <sdCards title="Documenten" v-if="offer?.product?.files?.length > 0 && offer?.type !== 'SOLARCONFIRM'">
     <div v-for="(item,index) in offer?.product?.files" :key="index">
      <a :href="item?.fileURL" class="w-100">
       &#x2022; {{ item?.name }}
      </a>
     </div>
    </sdCards>
    <a-form :model="offer" layout="vertical" @finish="signOffer">
     <sdCards title="Ondertekenen">
      <a-row :gutter="30"
             v-if="(offer?.product?.customerApprovals.length > 0 && offer?.type !== 'SOLARCONFIRM' )|| offer?.product.isProxy">
       <a-col :xs="24" class="mb-5">
        <CheckListWrap>
         <div class="sDash_check-list-wrap">
          <ul class="sDash_check-list sDash_check-list--left">
           <li v-for="(item,index) in offer?.product?.customerApprovals" :key="index" style="margin-bottom: 0px">
            <a-form-item label="" :name="['product','customerApprovalsValidation', index]"
                         :rules="{ validator: checkCheckBox }" style="margin-bottom: 0px">
             <a-checkbox v-model:checked="offer.product.customerApprovalsValidation[index]"
                         :name="['product','customerApprovalsValidation', index]">
              <span style="color:#000000" v-html="item"></span>
             </a-checkbox>
            </a-form-item>
           </li>
          </ul>
         </div>
        </CheckListWrap>
       </a-col>
       <a-col
        v-if="!loadingOffer && ((offer?.type !== 'SOLARSIMPLE' && offer?.type !== 'SOLAR') || offer?.product.isProxy)"
        :md="12" :xs="24" class="mb-5">
        <a-form-item label="IBAN" :name="['relation','bankAccountNumber']"
                     :rules="{required: true,message: 'Dit veld is verplicht'}">
         <a-input v-model:value="offer.relation.bankAccountNumber" :name="['relation','bankAccountNumber']"
                  placeholder=""/>
        </a-form-item>
       </a-col>
       <a-col
        v-if="!loadingOffer && ((offer?.type !== 'SOLARSIMPLE' && offer?.type !== 'SOLAR') || offer?.product.isProxy)"
        :md="12" :xs="24" class="mb-5">
        <a-form-item label="Tenaamstelling" :name="['relation','debtorName']"
                     :rules="{required: true,message: 'Dit veld is verplicht'}">
         <a-input v-model:value="offer.relation.debtorName" :name="['relation','debtorName']" placeholder=""/>
        </a-form-item>
       </a-col>
      </a-row>
      <a-row :gutter="30">
       <a-col :xs="24" class="signature align-center" v-if="offer?.eSignatureType === 'WET'">
        <Vue3Signature ref="signature1" :sigOption="sigState.option" :w="'450px'" :h="'200px'"
                       :disabled="sigState.disabled" @change="alert('2')"></Vue3Signature>
        <div v-if="signatureError" class="ant-form-item-explain ant-form-item-explain-error">
         <div role="alert">Uw handtekening is vereist</div>
        </div>
        <a-button size="large" class="btn-signin mt-5" type="default" @click.prevent="clearSignature"> Opnieuw
        </a-button>
       </a-col>
      </a-row>
     </sdCards>
     <sdCards>
      <a-row>
       <a-col :sm="12" :xs="24">
        <div class="sDash_form-action">
         <a-button size="large" class="btn-signin" type="primary" html-type="submit" :loading="loadingSignOffer"
                   :disabled="loadingSignOffer" @click="validateSignature"> Accorderen
         </a-button>
         <!--                <a-button size="large" class="btn-signin ml-5" type="danger" html-type="submit" :loading="loadingSignOffer"-->
         <!--                          :disabled="loadingSignOffer"> Weigeren-->
         <!--                </a-button>-->
        </div>
       </a-col>
      </a-row>
     </sdCards>
    </a-form>
   </a-col>
   <a-col :md="12" :xs="24">
    <div
     id="adobe-dc-view"
     style="width: 100%; box-shadow: 1px 1px 10px 1px #dadada;display: flex;height:90vh;flex-flow: column;"
    />

   </a-col>
  </a-row>
  <a-row v-if="!loadingOffer && loadingOfferError" :gutter="30">
   <a-col :md="12" :xs="24">
    <sdCards headless>
     Geachte Heer/Mevrouw,<br>
     <br>
     Deze link waarmee je deze pagina probeert te bezoeken is incorrect of mogelijk verlopen. Contacteer de
     klantenservice voor meer informatie.
    </sdCards>
   </a-col>
  </a-row>
 </Main>
</template>
<script>
import {computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref} from 'vue';
import {CheckListWrap, Main} from '../styled';
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import Aom from "../../components/aom/Aom";
// import FilesListTable from "./component/FilesTable.vue";
import {checkCheckBox} from "../../utility/validators";

const Sign = defineComponent({
 name: 'Sign',
 components: {Main, Aom, CheckListWrap},
 data() {
  return {
   showEditDrawer: false,
  }
 },
 setup() {
  const {state, dispatch} = useStore();
  const {params} = useRoute();
  const offer = reactive(computed(() => state.customer.offer));
  const loadingOffer = reactive(computed(() => state.customer.loadingOffer));
  const loadingSignOffer = reactive(computed(() => state.customer.loadingSignOffer));
  const loadingOfferError = reactive(computed(() => state.customer.loadingOfferError));
  const loadingFileUpload = reactive(computed(() => state.file.loadingFileUpload));
  const loadingPayOffer = reactive(computed(() => state.customer.loadingPayOffer));
  const apiEndpoint = process.env.VUE_APP_API_ENDPOINT;
  const signatureError = ref(false);
  const showFileUploadRef = ref(false);
  const fileList = ref([]);
  const fileUpload = ref(
   {
    type: 'CUSTOMERUPLOAD',
   }
  );
  const sigState = reactive({
   count: 0,
   option: {
    penColor: "rgb(0, 0, 0)",
    backgroundColor: "rgb(220,220,220)",
   },
   disabled: false
  })

  const approvals = ref([]);

  const signature1 = ref()

  const clearSignature = () => {
   signature1.value.clear()
  }

  const validateSignature = () => {
   signatureError.value = offer?.value.eSignatureType === "WET" ? signature1.value.isEmpty() : false
  }

  const signOffer = async () => {
   signatureError.value = false;
   if (offer?.value.eSignatureType === "NONE" || !signature1.value.isEmpty()) {
    await dispatch('signOffer', {
     token: params.token,
     tenant: params.tenant,
     bankAccountNumber: offer.value.relation.bankAccountNumber,
     debtorName: offer.value.relation.debtorName,
     signatureData: offer?.value.eSignatureType === "WET" ? signature1.value.save('image/svg+xml') : "",
    });
   } else {
    signatureError.value = true;
   }
  }

  const openInNewTab = (url) => {
   // Controleer of de URL is opgegeven
   if (!url) {
    console.error('Geen URL opgegeven');
    return;
   }
   // Open de URL in een nieuw tabblad
   window.open(url, '_parent');
  }

  const pay = async () => {
   await dispatch('generatePayLink', {
    tenant: params.tenant,
    token: params.token,
    openLink: openInNewTab,
   });
  }

  const handleFileUploadCancel = () => {
   fileUpload.value.type = 'CUSTOMERUPLOAD';
   fileList.value = [];
   showFileUploadRef.value = false;
  };

  const showFileUpload = () => {
   showFileUploadRef.value = true;
  };

  const uploading = ref(false);
  const beforeUpload = (file) => {
   fileList.value = [...fileList.value, file];
   return false;
  };

  const handleFileRemove = (file) => {
   const index = fileList.value.indexOf(file);
   const newFileList = fileList.value.slice();
   newFileList.splice(index, 1);
   fileList.value = newFileList;
  };

  const handleUpload = async () => {
   let formData = new FormData();
   formData.append("file", fileList.value[0]);
   formData.append("type", 'CUSTOMERUPLOAD');
   await dispatch('uploadFile', {formData: formData, token: params.token});
   await handleFileUploadCancel();
  }

  onBeforeUnmount(() => {
   dispatch('cleanOffer');
  });

  onMounted(async () => {
   await dispatch('cleanOffer');
   await dispatch('getOffer', params);
   const viewerConfig = {
    embedMode: 'SIZED_CONTAINER',
    showPageControls: true,
   }

   setTimeout(() => {
    // eslint-disable-next-line no-undef
    const adobeDCView = new AdobeDC.View({
     clientId: 'c09c49937c4043cb9d55dab5cdac87f9',
     divId: 'adobe-dc-view',
    })

    adobeDCView.previewFile({
     content: {
      location: {
       url: `${process.env.VUE_APP_API_ENDPOINT}${params.tenant}/offer/${params.token}/pdf`,
      },
     },
     metaData: {
      /* file name */
      fileName: `Aanbod.pdf`,
     },
    }, viewerConfig)
   }, 500)

  });

  return {
   offer,
   loadingOffer,
   loadingOfferError,
   sigState,
   clearSignature,
   signature1,
   loadingSignOffer,
   signOffer,
   Aom,
   approvals,
   checkCheckBox,
   signatureError,
   validateSignature,
   params,
   apiEndpoint,
   showFileUploadRef,
   handleFileUploadCancel,
   showFileUpload,
   uploading,
   beforeUpload,
   fileUpload,
   fileList,
   handleFileRemove,
   handleUpload,
   loadingFileUpload,
   pay,
   loadingPayOffer,
  };
 },
});

export default Sign;
</script>

<style>

.signature canvas {
 width: 450px;
 height: 200px;
}

.checkbox-group .ant-form-item-control-input {
 min-height: 0px !important;
}
</style>
<style>
.pdfobject-container {
 height: 30rem;
 border: 1rem solid rgba(0, 0, 0, .1);
}
</style>
